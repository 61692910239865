.home {
  width: 100%;
  margin: auto;
  animation: fadeIn 1s linear;

  &-bio {
    padding-bottom: 20px;
    width: 100%;
    max-width: var(--max-content-width);
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    &-text {
      text-align: center;
      z-index: 1;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(startYposition);
  }

  100% {
    opacity: 1;
    transform: translateY(endYposition);
  }
}

@keyframes roundandround {
  to {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

@keyframes show {
  to {
    opacity: 1;
  }
}