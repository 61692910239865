@import 'src/css/screenMixins.scss';

:root {
  --max-content-width: 1400px;
  --header-height: 100px;
  --header-scroll-height: 75px;
  --sidebar-width: 100px;
  --content-padding: 0 100px;

  --opening-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  --easing: cubic-bezier(0.645, 0.045, 0.355, 1);

  --tablet: 760px;

  @include for-tablet {
    --content-padding: 0;
    --max-content-width: 100vw;
  }
}

body {
  font-family: europa, sans-serif;
  font-style: normal;

  --primary-color: rgb(255, 255, 255);
  --primary-color-transparent: rgba(39, 57, 78, 0.8);
  --link-color: #4666ab;
  --link-color-light: #4666ab;
  --link-color-dark: white;
  --light-blue: #77abdc;
  --lc-color: #4666ab;
  --lc-color-transparent: rgba(70, 102, 171, 0.9);
  --tertiary-color: rgb(150, 159, 256);
  --header-color: #27384d;
  --body-text-color: #27384d;
  --body-text-color-opposite: white;
  --button-background-color: rgba(70, 102, 171, 0.1);

  &.dark {
    --primary-color: rgb(39, 57, 78);
    --primary-color-transparent: rgba(39, 57, 78, 0.8);
    --link-color: white;
    --lc-color: #4965a1;
    --header-color: white;
    --body-text-color: white;
    --body-text-color-opposite: #27384d;
    --button-background-color: rgba(255, 255, 255, 0.1);
  }

  &.no-scroll {
    overflow: hidden;
    .content > * {
      filter: blur(2px) brightness(0.7);
      transition: ease 1s;
      pointer-events: none;
      user-select: none;
    }
  }

  color: var(--body-text-color);
  background-color: var(--primary-color);
}

%button {
  border: 1px solid var(--link-color);
  padding: 10px 20px;
  border-radius: 8px;
}

h1 {
  color: var(--header-color);
  font-size: clamp(36px, 3vw, 48px);
  font-weight: bold;
  margin: 5px 0;
}

h2 {
  color: var(--header-color);
  font-size: clamp(24px, 2.5vw, 42px);
  font-weight: bold;
  margin: 5px 0;
}

h3 {
  color: var(--header-color);
  font-size: clamp(20px, 2vw, 25px);
  margin: 5px 0;
}

h4 {
  color: var(--header-color);
  font-size: clamp(16px, 1.5vw, 18px);
  margin: 5px 0;
}

.container {
  max-width: var(--max-content-width);
  padding: var(--content-padding);
  margin: auto;
}

a {
  color: var(--link-color);
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

button {
  border: none;
  background-color: unset;
  cursor: pointer;
  &.button {
    &-primary {
      @extend %button;
      background-color: var(--button-background-color);
      color: var(--link-color);
    }
    &-secondary {
      @extend %button;
      background-color: var(--link-color);
      color: var(--primary-color);
    }
  }
}

.icon-fill {
  fill: var(--link-color) !important;
}