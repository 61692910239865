@import 'src/css/screenMixins.scss';

.experience {
  display: flex;
  justify-content: center;
  min-height: 350px;
  margin: 20px 0;

  &-content {
    max-width: var(--max-content-width);
    padding: var(--content-padding);
    width: 100%;
  }

  .experiences {
    display: flex;

    @include for-tablet {
      flex-direction: column;
    }

    &-list {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: clamp(100px, 300px, 100%);

      @include for-tablet {
        display: flex;
        width: 100%;
      }

      &-item {
        width: 100%;
        padding: 10px 20px;
        color: var(--body-text-color);
        border-left: 2px solid var(--body-text-color);
        opacity: 0.5;
        text-align: left;

        &-active {
          opacity: 1;
          position: relative;
          background-color: var(--button-background-color);
          transition: border-color 1s ease;
        }
      }
    }

    &-description {
      margin: 0;
      padding: 0 10px;
      width: calc(100% - 300px);

      h5 {
        margin-bottom: 0;
        text-decoration: underline;
      }

      ul {
        margin: 0;
      }

      @include for-tablet {
        width: 100%;
        padding: 0;
      }
    }
  }
}