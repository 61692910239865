@import 'src/css/screenMixins';

.screen-container {
  background-color: var(--primary-color);
  height: auto;
  display: grid;
  grid-template-rows: var(--header-height) auto;
  grid-template-areas:
    'header'
    'content';

  .sidebar {
    width: var(--sidebar-width);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;

    @include for-tablet {
      display: none;
    }

    &-links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 0;
      margin: 0;
      border-radius: 0 20px 0 0;
      list-style: none;

      .sidebar-link {
        margin-bottom: 20px;

        &:hover {
          cursor: pointer;
        }

        a {
          color: var(--link-color);
        }

        svg {
          width: 45px;
          height: 45px;
        }

        &:first-of-type {
          margin-top: 30px;
        }

        &:last-of-type {
          margin-bottom: 30px;
        }
      }

      &::after,
      &::before {
        content: "";
        display: block;
        width: 1px;
        margin: 0px auto;

      }

      &::before {
        height: 50px;
      }

      &::after {
        height: 90px;
        background-color: var(--link-color);
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    grid-area: header;
    width: 100%;
    z-index: 2;
    opacity: 0.9;
    background-color: var(--primary-color);
    color: white;

    @include for-tablet {
      max-width: 100vw;
      opacity: 1;
      padding-left: 0;
    }

    &-mobile {
      @include for-tablet {
        height: 100%;
        position: fixed;
        align-items: flex-start;
        background-color: var(--primary-color-transparent);

        .header-content {
          margin: 0;
        }
      }
    }

    &-content {
      max-width: var(--max-content-width);
      padding: var(--content-padding);
      width: 100%;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      position: relative;
      overflow: hidden;

      @include for-tablet {
        padding: 0;

        a {
          margin-top: 5px;
          align-self: flex-start;
        }
      }

      .lc-logo {
        width: 50px;

        @include for-tablet {
          margin-top: 5px;
          margin-left: 20px;
        }
      }
    }

    &-nav {
      display: flex;

      &-link {
        margin: 0 20px;

        &.active {
          a {
            text-decoration: underline;
            transition: var(--opening-transition);
          }
        }
      }

      @include for-tablet {
        transform: translateX(100vw);
        visibility: hidden;
        width: 0;
      }

      &-mobile-open {
        display: flex;
        position: fixed;
        top: 0px;
        bottom: 0px;
        right: 0px;
        transform: translateX(0vw);
        visibility: visible;
        transition: var(--opening-transition);
        box-shadow: -10px 0px 30px -15px var(--primary-color);

        @include for-tablet {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: var(--lc-color);
          width: clamp(100px, 100%, 400px);
          min-height: 100vh;
          padding: 0;
          margin: 0;


          .header-nav-link {
            font-size: 20px;
            margin: 20px 0;

            a {
              color: white;
            }
          }
        }
      }
    }

    .toggle-button {
      margin-right: 10px;

      @include for-tablet {
        display: none;
      }
    }

    .mobile-menu-button {
      position: absolute;
      right: 15px;
      top: 25px;

      &-icon {
        width: 35px;
        opacity: 0;
        fill: var(--body-text-color);

        @include for-tablet {
          opacity: 1;
        }
      }
    }
  }

  .content {
    grid-area: content;
    width: 100%;

    @include for-tablet {
      width: calc(100% - 40px);
      padding: 0 20px
    }
  }
}

.fadedown-enter {
  opacity: 0.01;
  transform: translateY(-20px);
  transition: opacity 300ms var(--easing), transform 300ms var(--easing);
}

.fadedown-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 300ms var(--easing), transform 300ms var(--easing);
}

.header-up {
  position: fixed;
  height: var(--header-scroll-height);
  transform: translateY(0px);
  box-shadow: 0 10px 30px -10px var(--lc-color);
}

.header-down {
  height: var(--header-scroll-height);
  transform: translateY(calc(var(--header-height) * -1));
}